import React, { useEffect } from "react";
import { jsx } from "theme-ui";
import { motion, useAnimation } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";

/** @jsx jsx */

const AnimateImage = ({
  alt = "",
  src,
  isAnimated = false,
  full = false,
  sx,
  variants,
  transition,
  ...props
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (isAnimated) {
      controls.start("active");
    } else {
      controls.start("initial");
    }
  }, [controls, isAnimated]);

  return (
    <motion.div
      sx={{
        overflow: "hidden",
        position: "relative",
        display: "inline-block",
        ...sx,
      }}
      {...props}
    >
      <motion.div
        animate={controls}
        initial="initial"
        variants={variants}
        transition={transition}
        sx={{
          height: full ? "100%" : "auto",
          width: full ? "100%" : "initial",
          maxWidth: "100%",
          display: "block",
        }}
      >
        <GatsbyImage alt={alt} image={src} />
      </motion.div>
    </motion.div>
  );
};

export { AnimateImage as Image };
