import React, { useLayoutEffect, useRef, useState } from "react";
import { jsx, Box } from "theme-ui";
import { motion, useTransform, useViewportScroll } from "framer-motion";
import { GatsbyImage } from "gatsby-plugin-image";

/** @jsx jsx */

const Parallax = ({
  children,
  alt = "",
  often = 0.3,
  image,
  backgroundColor,
  loading = "eager",
  sx,
  onImageLoad,
  ...props
}) => {
  const [elementTop, setElementTop] = useState(0);
  const ref = useRef(null);
  const { scrollY } = useViewportScroll();

  const y = useTransform(scrollY, [elementTop, elementTop + 1], [0, often], {
    clamp: false,
  });

  useLayoutEffect(() => {
    const element = ref.current;
    setElementTop(element.offsetTop);
  }, [ref]);

  return (
    <Box
      ref={ref}
      sx={{ height: "100%", width: "100%", position: "relative", ...sx }}
      {...props}
    >
      <StyledImage
        alt={alt}
        image={image}
        backgroundColor={backgroundColor}
        style={{ y }}
        loading={loading}
        onLoad={onImageLoad}
      />
      <StyledContent>{children}</StyledContent>
    </Box>
  );
};

export { Parallax };

const MotionBox = motion.custom(Box);

const StyledImage = React.forwardRef(
  ({ alt, image, backgroundColor, onLoad, sx, loading, ...props }, ref) => (
    <MotionBox
      ref={ref}
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        ...sx,
      }}
      {...props}
    >
      <GatsbyImage
        image={image}
        alt={alt}
        style={{
          width: "100%",
          height: "100%",
          userDrag: "none",
          userSelect: "none",
        }}
        backgroundColor={backgroundColor}
        onLoad={onLoad}
        loading={loading}
      />
      {/*    <Image sx={{ objectFit: "cover", height: "100%" }} src={image} />*/}
      {/*    <Box
      sx={{
        backgroundColor: "background",
        backgroundImage: image ? `url(${image})` : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100%",
      }}
    />*/}
    </MotionBox>
  )
);

const StyledContent = ({ sx, ...props }) => (
  <Box
    sx={{
      height: "100%",
      width: "100%",
      position: "absolute",
      ...sx,
    }}
    {...props}
  />
);
