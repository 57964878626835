/**
 * Scale
 */

const getScale = (direction, delta) => {
  switch (direction) {
    case "in":
      return {
        start: 1,
        end: 1 + delta,
      };
    case "out":
      return {
        start: 1 + delta,
        end: 1,
      };
    default:
      throw new Error("direction not valide");
  }
};

const entranceScale = ({ direction = "in", delta = 0.15 } = {}) => ({
  initial: {
    scale: getScale(direction, delta).start,
  },
  active: {
    scale: getScale(direction, delta).end,
  },
});

export { entranceScale };
